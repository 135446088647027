import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Preset } from 'types/proto/retriver-struct_pb';
import { useIntersectionObserver } from './useIntersectionObserver';
import { Union } from 'utils/utils';
import { Retriver } from 'api/retriver/retriver';
import { NewSearchPresetRequest } from 'api/retriver/requestFactory/presets';

export interface SearchQuery {
  title?: string;
  description?: string;
  tags?: string;
}

interface useFetchPresetsProps {
  size?: number;
  showHidden?: boolean;
  query?: SearchQuery;
  lastItemRef: MutableRefObject<null>;
}

export const useFetchPresets = (
  props: useFetchPresetsProps
): [Preset[], Dispatch<SetStateAction<Preset[]>>, boolean] => {
  const [list, setList] = useState<Preset[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const offsetRef = useRef<string>('');
  const hasMore = useRef<boolean>(true);

  const fetchPresets = useCallback(
    async (
      showHidden: boolean,
      offset: string,
      size: number,
      query?: SearchQuery
    ) => {
      setIsLoading(true);
      const response = (
        await Retriver.do(
          NewSearchPresetRequest(
            offset,
            size,
            showHidden,
            query?.title,
            query?.description,
            query?.tags
          )
        )
      )?.searchPresetsResponse;
      setIsLoading(false);
      if (!response) {
        setHasError(true);
        return [];
      }
      hasMore.current = !(
        response.presets.length === 0 && response.offset === offset
      );
      offsetRef.current = response.offset;
      return response.presets;
    },
    []
  );

  const fetchNextPage = useCallback(
    async (showHidden: boolean, size: number, query?: SearchQuery) => {
      const data = await fetchPresets(
        showHidden,
        offsetRef.current,
        size,
        query
      );
      setList((prev) => Union(prev, data) as Preset[]);
    },
    [fetchPresets]
  );

  const fetchInit = useCallback(
    async (showHidden: boolean, size: number, query?: SearchQuery) => {
      const data = await fetchPresets(
        showHidden,
        offsetRef.current,
        size,
        query
      );
      setList((prev) => Union(prev, data) as Preset[]);
    },
    [fetchPresets]
  );

  useEffect(() => {
    hasMore.current = true;
    offsetRef.current = '';
    setList([]);
    fetchInit(props.showHidden ?? false, props.size ?? 30, props.query);
  }, [fetchInit, props.query, props.showHidden, props.size]);

  useIntersectionObserver({
    root: null,
    target: props.lastItemRef?.current,
    onIntersect: ([{ isIntersecting }]) => {
      if (isIntersecting && !isLoading && !hasError) {
        fetchNextPage(props.showHidden ?? false, props.size ?? 30, props.query);
      }
    },
  });

  return [list, setList, hasMore.current];
};
